import {ApiResponse, BaseHttpService, Company} from '@ems/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({providedIn: 'root'})
export class CompanyService extends BaseHttpService {
  override cacheEnabled = true;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }

  getCompany(id: number): Observable<any> {
    const params = {
      'loader': false,
      'expand[0]': 'mailingAddress',
      'expand[1]': 'visitingAddress',
      'expand[2]': 'billingAddress',
      'expand[3]': 'parent',
      'expand[4]': 'subsidiaries',
      'expand[5]': 'billingDetails',
      'expand[6]': 'updatedBy',
      'expand[7]': 'sections'
    }
    return this.get(`company/${id}`, params);
  }

  getCompanies(
    types: string | string[],
    query?: string,
    hasSubsidiaries?: boolean,
    page = 1,
    pageSize = 20
  ): Observable<ApiResponse<Company[]>> {
    const queryParams: Record<string, any> = {
      page,
      pageSize,
      'loader': false,
      'expand[0]': 'sections',
      'expand[1]': 'subsidiaries',
      ...(hasSubsidiaries ? {'filter[subsidiaries][has]': '1'} : {}),
      ...(query ? {'filter[name][like]': `%${query}%`} : {}),
      ...(Array.isArray(types)
        ? types.reduce((acc: any, curr: string, i: number) => {
            acc[`filter[business_relationship][in][${i}]`] = curr;
            return acc;
          }, {})
        : {}
      ),
      ...(typeof types === 'string' ? {'filter[business_relationship][eq]': types} : {}),
    };

    return this.get('companies', queryParams);
  }

  getAllCompanies(
    types: string | string[],
    parentId?: number,
    pageSize = 20
  ): Observable<Company[]> {
    const queryParams: Record<string, any> = {
      pageSize,
      'loader': false,
      'expand[0]': 'sections',
      'expand[1]': 'subsidiaries',
      ...(typeof parentId === 'number' && parentId > 0 ? {'filter[parent_id][is_null]': parentId} : {}),
      ...(Array.isArray(types)
        ? types.reduce((acc: any, curr: string, i: number) => {
            acc[`filter[business_relationship][in][${i}]`] = curr;
            return acc;
          }, {})
        : {}
      ),
      ...(typeof types === 'string' ? {'filter[business_relationship][eq]': types} : {}),
    };

    return this.getAll('companies', queryParams);
  }

  getCompaniesByQuery(query?: string, page = 1, pageSize = 5, types?: string | string[]): Observable<ApiResponse<Company[]>> {
    const queryParams: any = {
      loader: false,
      'expand[0]': 'mailingAddress',
      page,
      pageSize,
      sort: '+last_name',
      ...(query ? { query } : {}),
      ...(Array.isArray(types)
          ? types.reduce((acc: any, curr: string, i: number) => {
            acc[`filter[business_relationship][in][${i}]`] = curr;
            return acc;
          }, {})
          : {}
      ),
      ...(typeof types === 'string' ? {'filter[business_relationship][eq]': types} : {}),
    };
    return this.get('companies', queryParams);
  }

  create(body: any): Observable<any> {
    const queryParams = {
      'loader': false,
      'expand[0]': 'billingAddress',
      'expand[1]': 'visitingAddress',
      'expand[2]': 'mailingAddress',
      'expand[3]': 'createdBy',
      'expand[5]': 'updatedBy',
      'expand[6]': 'billingDetails',
    };
    return this.post('companies', body, {}, queryParams);
  }

  update(id: number, body: any): Observable<any> {
    this.invalidateCache();
    const queryParams = {
      'loader': false,
      'expand[0]': 'billingAddress',
      'expand[1]': 'visitingAddress',
      'expand[2]': 'mailingAddress',
      'expand[3]': 'updatedBy',
      'expand[4]': 'billingDetails',
    };
    return this.post('company/' + id, body, {}, queryParams);
  }
}
