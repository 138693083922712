import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';

import { sortByField } from '../../utils/sort.utils';

export interface DropdownItem {
  label: string;
  value: number;
  showBranchNumber: boolean;
  hasSections: boolean;
  sections: any[];
  defaultRegion: any;
  hasSubsidiaries: boolean;
  subsidiaries: any[];
}

function mapToDropDownItem(el: any): DropdownItem {
  return {
    label: el.pseudonym || el.name,
    value: el.id,
    showBranchNumber: el.show_branch_number,
    hasSections: !!el.sections.length,
    sections: el.sections,
    defaultRegion: el.default_region,
    hasSubsidiaries: !!el.subsidiaries.length,
    subsidiaries: el.subsidiaries
  };
}

@Injectable({
  providedIn: 'root',
})
export class CompanySelectorService {
  private _companyId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private _selectedCompanyIndex$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private _selectedCompany$: BehaviorSubject<DropdownItem | null> = new BehaviorSubject<DropdownItem | null>(null);
  private _companies$: BehaviorSubject<DropdownItem[]> = new BehaviorSubject<DropdownItem[]>([]);

  companyId$ = this._companyId$.asObservable();
  selectedCompanyIndex$ = this._selectedCompanyIndex$.asObservable();
  selectedCompany$ = this._selectedCompany$.asObservable();
  companies$ = this._companies$.asObservable();

  constructor(private cookieService: CookieService) {}

  setCompanyId(companyId: number) {
    this.cookieService.set('company_id', companyId.toString(), 0, '/');

    // setSelectedData first, because it sets cookies data, which is relevant in companyId$ subscriptions
    this.setSelectedData(companyId);

    this._companyId$.next(companyId);
  }

  getCompanyId() {
    return this._companyId$.getValue();
  }

  getSelectedCompany(): DropdownItem | undefined {
    return this._companies$.getValue().find((el: DropdownItem) => el.value === this.getCompanyId());
  }

  setCompanies(items: any[]) {
    const sortedItems = items.map(mapToDropDownItem).sort(sortByField('label'));
    // set companies data
    this._companies$.next(sortedItems);

    // get selected companyId from cookies or first as default
    const companyId = parseInt(this.cookieService.get('company_id')) || sortedItems[0].value;
    this.setCompanyId(companyId);
  }

  private setSelectedData(companyId: number) {
    const companies = this._companies$.getValue();

    // on page load the list of companies if empty
    if (!companies?.length) {
      return;
    }

    let target = companies?.find((el: DropdownItem) => el.value === companyId)! || companies[0];

    // first set cookies, because in other places where companyId$ is subscribed to they will be relevant
    this.cookieService.set('showBranchNumber', target.showBranchNumber ? '1' : '0', 0, '/');
    this.cookieService.set('hasSections', target.hasSections ? '1' : '0', 0, '/');
    this.cookieService.set('hasSubsidiaries', target.hasSubsidiaries ? '1' : '0', 0, '/');
    let subsidiaries;
    if(target.hasSubsidiaries) {
      subsidiaries = Object.entries(target.subsidiaries).map(([_, value]) => {
        return {
          value: value.id,
          label: value.pseudonym || value.name,
        };
      });
      this.cookieService.set('subsidiaries', JSON.stringify(subsidiaries), 0, '/');
    }
    let defaultRegion;
    if(target.hasSections) {
      defaultRegion = Object.entries(target.sections).map(([_, value]) => {
        return {
          defaultRegion: value.default_region,
          section: value.id,
        };
      });
    } else {
      defaultRegion = target.defaultRegion;
    }
    this.cookieService.set('defaultRegion', JSON.stringify(defaultRegion), 0, '/');
    this._selectedCompany$.next(target);

    let targetIndex = companies?.findIndex((el: DropdownItem) => el.value === companyId)!;
    if(targetIndex === -1 && companies?.length > 0) { targetIndex = 0; }

    this._selectedCompanyIndex$.next(targetIndex);
  }
}
