import { inject, Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpRequest} from '@angular/common/http';
import {EMPTY, expand, map, Observable, reduce} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { CacheBucket, HttpCacheManager, withCache } from '@ngneat/cashew';
import { ApiUrlService } from './api-url.service';

@Injectable({providedIn: 'root'})
export class BaseHttpService {
  private baseUrl = '';

  private readonly baseHttpHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  private _params: {[param: string]: string} = {};

  private bucket = new CacheBucket();
  protected cacheEnabled = false;

  private apiUrlService = inject(ApiUrlService);

  constructor(
    private http: HttpClient,
    protected cookieService: CookieService,
    private manager: HttpCacheManager
  ) {
    this.baseUrl = this.apiUrlService.baseUrl;
  }

  get(url: string, params = {}): Observable<any> {
    return this.http.get(this.addBaseUrl(url), {
      headers: this.headers(),
      params: this.params(params),
      ...(this.cacheEnabled ? { context: withCache({bucket: this.bucket})}: {}),
    });
  }

  getAll(url: string, params = {}): Observable<any[]> {
    const _url = this.appendUrlParams(this.addBaseUrl(url), this.params(params));
    return this.http.get(_url, {
      headers: this.headers(),
      ...(this.cacheEnabled ? { context: withCache({bucket: this.bucket})}: {}),
    }).pipe(
      expand(({ meta }: any) => {
        return meta?.current_page && meta.current_page < meta.last_page
          ? this.http.get(
            this.replaceOrAddPageParameter(_url, meta.current_page + 1),
            {...(this.cacheEnabled ? { context: withCache({bucket: this.bucket})}: {})}
          )
          : EMPTY;
      }),
      reduce((acc, res: any) => acc.concat(res.data), []),
    );
  }

  post(url: string, data: Record<string, unknown> = {}, headers: Record<string, unknown> = {}, params = {}): Observable<any> {
    return this.http.post(this.addBaseUrl(url), JSON.stringify(data), {
      headers: this.headers(headers),
      params: this.params(params)
    });
  }

  postFormData(url: string, data: Record<string, unknown> = {}): Observable<any> {
    return this.http
      .post(this.addBaseUrl(url), data, {
        reportProgress: true,
        observe: 'events'
      });
  }

  put(url: string, data: Record<string, unknown> = {}, headers: Record<string, unknown> = {}): Observable<any> {
    return this.http.put(this.addBaseUrl(url), JSON.stringify(data), { headers: this.headers(headers) });
  }

  patch(url: string, data: Record<string, unknown> = {}, headers: Record<string, unknown> = {}): Observable<any> {
    return this.http.patch(this.addBaseUrl(url), JSON.stringify(data), { headers: this.headers(headers) });
  }

  delete(url: string, body?: any): Observable<void> {
    return this.http.delete<void>(this.addBaseUrl(url), {
      headers: this.headers(),
      ...(body ? { body } : {})
    });
  }

  enableCustomerCompanyIdFilter() {
    const companyId = this.cookieService.get('company_id');
    if(companyId) {
      this._params['filter[customer_company_id][eq]'] = companyId;
    }
  }

  private addBaseUrl(url: string) {
    return `${this.baseUrl}${url}`;
  }

  private headers(additionalHeaders = {}): HttpHeaders {
    const headersConfig = {
      ...this.baseHttpHeaders,
      ...additionalHeaders,
    };

    return new HttpHeaders(headersConfig);
  }

  private params(additionalParams = {}): HttpParams {
    return new HttpParams({
      fromObject: {
        ...this._params,
        ...additionalParams,
      },
    });
  }

  private replaceOrAddPageParameter(url: string, newPageValue: number) {
    const urlObj = new URL(url);

    if (urlObj.searchParams.has('page')) {
      urlObj.searchParams.set('page', newPageValue.toString());
    } else {
      urlObj.searchParams.append('page', newPageValue.toString());
    }

    return urlObj.toString();
  }

  private appendUrlParams(url: string, params: HttpParams): string {
    return new URL(`${url}?${params.toString()}`).toString();
  }


  addQueryParams(url: string, params: {[key: string]: string}) {
    const urlParams = Object.keys(params).length
      ? new HttpParams({
        fromObject: params
      }).toString()
      : '';

    return `${url}${urlParams ? '?' + urlParams : ''}`;
  }

  invalidateCache() {
    this.manager.delete(this.bucket);
  }
}
