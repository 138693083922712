import { ApiResponse, BaseHttpService } from '@ems/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable, switchMap } from 'rxjs';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({ providedIn: 'root' })
export class ProductService extends BaseHttpService {
  override cacheEnabled = false;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }

  getProduct(id: number): Observable<ApiResponse<any>> {
    this.invalidateCache();
    const url = `products/${id}`;
    const queryParams = {
      'loader': false,
      'expand[0]': 'createdBy',
      'expand[1]': 'section',
      'expand[2]': 'printingCompanies',
      'expand[3]': 'updatedBy'
    };
    return this.get(url, queryParams);
  }

  getList(params: any): Observable<ApiResponse<any>> {
    this.enableCustomerCompanyIdFilter();
    return this.get('products', params);
  }

  createProduct(body: any): Observable<any> {
    const params = {
      'loader': false,
      'expand[0]': 'createdBy',
      'expand[1]': 'section',
    };
    return this.post('products', body, {}, params);
  }

  copyProduct(id: string): Observable<any> {
    return this.post(`products/copy/${id}`).pipe(
      // @Todo: switchMap to get the condition can be removed when dhe copy endpoint accepts expand params
      switchMap(({data}: ApiResponse<any>) => this.getProduct(data.id))
    );
  }

  updateProduct(id: number, body: any): Observable<any> {
    const params = {
      'loader': false,
      'expand[0]': 'updatedBy'
    };
    return this.post(`products/${id}`, body, {}, params);
  }

  deleteProduct(id: number): Observable<any> {
    return this.delete('products/' + id);
  }
}
