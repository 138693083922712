import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse, BaseHttpService } from '@ems/api';
import { CookieService } from 'ngx-cookie-service';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({
  providedIn: 'root'
})
export class ProductPriceService extends BaseHttpService {
  override cacheEnabled = false;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }

  getList(params: any): Observable<ApiResponse<any>> {
    return this.get('product-prices', params);
  }

  getDetail(id: number): Observable<any> {
    const params = {
      'loader': false,
      'expand[0]': 'printingCompany',
      'expand[1]': 'updatedBy'
    };
    return this.get('product-prices/' + id, params);
  }

  createProductPrice(data: any): Observable<any> {
    return this.post('product-prices', data);
  }

  updateProductPrice(id: number, data: any): Observable<any> {
    return this.post('product-prices/' + id, data);
  }

  deleteProductPrice(id: number): Observable<any> {
    return this.delete('product-prices/' + id);
  }
}
