import { Component, ViewChild, OnInit, DestroyRef, inject } from '@angular/core';
import { DropDownListComponent, DropDownListModule } from 'smart-webcomponents-angular/dropdownlist';
import {
  faArrowRightFromBracket,
  faBars,
  faTimes,
  faHome,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { KeycloakService } from 'keycloak-angular';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { RolesService } from '../../../utils/roles.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CommonModule, NgForOf, NgIf } from '@angular/common';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { AppVersionComponent } from '../../../shared/components/app-version/app-version.component';
import { CompanySelectorService, DropdownItem } from '../../../shared/services/company-selector.service';
import { tap } from 'rxjs/operators';
import { map, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { sortByField } from '../../../utils/sort.utils';
import { NavigationToggleService } from '../../../shared/services/navigation-toggle.service';
import { Favicons } from '../../../app.constants';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-frame',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    AppVersionComponent,
    DropDownListModule,
    FaIconComponent,
    NgForOf,
    NgIf,
    RouterLink,
    RouterOutlet
  ],
  templateUrl: './frame.component.html',
  styleUrl: './frame.component.scss'
})
export class FrameComponent implements OnInit {
  private readonly keycloak = inject(KeycloakService);
  private cookieService = inject(CookieService);
  protected router = inject(Router);
  private rolesService = inject(RolesService);
  private companySelectorService = inject(CompanySelectorService);
  private navigationToggleService = inject(NavigationToggleService);
  private destroyRef = inject(DestroyRef);
  private title = inject(Title);

  @ViewChild('dropdownCompaniesRef', { read: DropDownListComponent, static: false }) dropdownCompaniesRef!: DropDownListComponent;

  protected readonly faLogout = faArrowRightFromBracket;
  protected readonly faBars = faBars;
  protected readonly faTimes = faTimes;
  protected readonly faHome = faHome;
  protected readonly faQuestionCircle = faQuestionCircle;

  protected env = environment;

  companiesList$ = this.companySelectorService.companies$.pipe(
    tap(() => this.initCompaniesDropDown())
  );
  selectedCompanyIndex$: Observable<number[]> = this.companySelectorService.selectedCompanyIndex$.pipe(
    map((index: number | null) => index === null ? [] : [index])
  );
  selectedCompany: DropdownItem | null = null;

  menuUser: any[] = [];
  readonly companySelectEnabledRoutes = ['/home', '/filialen', '/ansprechpartner', '/produkte'];
  readonly open$ = this.navigationToggleService.open$;

  get pageTitle() {
    const suffix = this.selectedCompany?.label
      && this.companySelectEnabledRoutes.some((targetUrl: string) => this.router.url.startsWith(targetUrl))
      && this.router.url !== '/home'
      && !this.hasRoleRtgOrCustomer
      ? ` | ${this.selectedCompany.label}` : '';
    return `Stammdatenverwaltung${suffix}`;
  }

  get hasRoleRtgOrCustomer() {
    return this.rolesService.hasRoleRtgOrCustomer();
  }

  get logo() {
    return this.rolesService.getCurrentClient() === 'sug' ? 'sug-logo' : 'ems-logo';
  }

  get logoSmartTomm() {
    return this.env.envName === 'test' || this.env.envName === 'stage' ? '.test' : '';
  }

  ngOnInit() {
    this.initFavicon();
    this.initTitle();
    this.rolesService.setAllUserRolesCookies();
    this.rolesService.setMenuUser();
    this.menuUser = this.rolesService.getMenuUser().sort(sortByField('name'));

    this.companySelectorService.selectedCompany$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((selectedCompany: DropdownItem | null) => {
      this.selectedCompany = selectedCompany;
    });

    if(this.router.url.startsWith('/home')) {
      this.onSidebarToggle();
    }
  }

  onCompanyChange(event: CustomEvent) {
    const companyId = parseInt(event.detail.value || '0');
    // setting the company_id programmatically with the service triggers the smart-drop-down-list onCompanyChange resulting in a double companyId$ event
    // so we stop here to not call this.companySelectorService.setCompanyId once again
    if (!companyId || this.companySelectorService.getCompanyId() === companyId) {
      return;
    }
    this.companySelectorService.setCompanyId(companyId);
    this.toggleSidebarOpen(this.router.url);
  }

  onNavigate(path: string) {
    path = path || '/home';
    const companyId = this.companySelectorService.getCompanyId();
    if (!companyId) {
      return;
    }

    this.dropdownCompaniesRef.disabled = false;
    this.cookieService.set('verwaltung', path);
    this.router.navigateByUrl(path);

    if(path === '/home') {
      this.navigationToggleService.open();
    } else {
      this.navigationToggleService.close();
    }
  }

  onSidebarToggle() {
    this.navigationToggleService.toggle();
  }

  private toggleSidebarOpen(path: string) {
    if(path === '/home') {
      this.navigationToggleService.open();
    }
  }

  private initCompaniesDropDown() {
    setTimeout(() => (this.dropdownCompaniesRef.disabled = false));
  }

  private initFavicon() {
    const tenant = this.rolesService.getCurrentClient();

    document.getElementById('favicon')?.setAttribute('href', tenant === 'sug' ? Favicons.Sug : Favicons.Base);
    document.getElementById('favicon16')?.setAttribute('href', tenant === 'sug' ? Favicons.Sug16 : Favicons.Base16);
    document.getElementById('favicon32')?.setAttribute('href', tenant === 'sug' ? Favicons.Sug32 : Favicons.Base32);
  }

  private initTitle() {
    const tenant = this.rolesService.getCurrentClient();
    const title = tenant === 'sug' ? 'S&G Stammdatenverwaltung' : tenant === 'ems' ? 'ems Stammdatenverwaltung': 'Stammdatenverwaltung';
    this.title.setTitle(title);
  }

  async logout() {
    await this.keycloak.logout();
  }
}
